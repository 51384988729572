import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79')
];

export const server_loads = [];

export const dictionary = {
		"/accept-invite": [~69],
		"/complete-setup": [70],
		"/error": [71],
		"/healthz": [72],
		"/login": [73],
		"/login/error": [74],
		"/onboarding": [75],
		"/signup-confirmation": [77],
		"/signup-retry": [78],
		"/signup": [76],
		"/switch/[org]": [79],
		"/[org]": [14,[2]],
		"/[org]/apps": [15,[2]],
		"/[org]/apps/new": [18,[2]],
		"/[org]/apps/playground": [19,[2,4]],
		"/[org]/apps/private/[id]": [20,[2]],
		"/[org]/apps/private/[id]/[version]": [21,[2,5]],
		"/[org]/apps/private/[id]/[version]/events": [22,[2,5]],
		"/[org]/apps/private/[id]/[version]/logs": [23,[2,5]],
		"/[org]/apps/[id]": [16,[2,3]],
		"/[org]/apps/[id]/credentials": [17,[2,3]],
		"/[org]/deploy": [24,[2]],
		"/[org]/deploy/[recipeId]": [25,[2]],
		"/[org]/governance": [26,[2]],
		"/[org]/governance/ownership": [27,[2]],
		"/[org]/governance/ownership/[typeId]": [28,[2]],
		"/[org]/governance/products-features": [29,[2]],
		"/[org]/governance/tags": [30,[2]],
		"/[org]/governance/tags/classification/[classificationId]/[[valueId]]": [31,[2]],
		"/[org]/governance/tags/label/[labelId]": [32,[2]],
		"/[org]/import": [33,[2,6]],
		"/[org]/import/private/[privateAppId]": [36,[2,6]],
		"/[org]/import/private/[privateAppId]/new": [37,[2]],
		"/[org]/import/[appId]": [34,[2,6]],
		"/[org]/import/[appId]/new": [35,[2]],
		"/[org]/inbox/[[messageId]]": [38,[2]],
		"/[org]/link/github-app": [39],
		"/[org]/people": [40,[2]],
		"/[org]/people/invite": [42,[2]],
		"/[org]/people/my-profile": [43,[2]],
		"/[org]/people/pending": [44,[2]],
		"/[org]/people/[personId]": [41,[2]],
		"/[org]/projects": [45,[2]],
		"/[org]/projects/[projectId]": [46,[2,7]],
		"/[org]/projects/[projectId]/deployments": [47,[2,7]],
		"/[org]/projects/[projectId]/deployments/[deploymentId]": [48,[2,7,8]],
		"/[org]/projects/[projectId]/deployments/[deploymentId]/logs": [49,[2,7,8]],
		"/[org]/projects/[projectId]/edit": [50,[2]],
		"/[org]/projects/[projectId]/logs": [51,[2,7]],
		"/[org]/projects/[projectId]/resources": [52,[2,7]],
		"/[org]/projects/[projectId]/variables": [53,[2,7]],
		"/[org]/recipes": [54,[2]],
		"/[org]/recipes/application/[recipeId]/display": [56,[2,9]],
		"/[org]/recipes/application/[recipeId]/variables": [57,[2,9]],
		"/[org]/recipes/application/[recipeId]/[[stepId]]": [55,[2,9,10]],
		"/[org]/recipes/workflow/[recipeId]/display": [59,[2,11]],
		"/[org]/recipes/workflow/[recipeId]/[[stepId]]": [58,[2,11]],
		"/[org]/resources": [60,[2]],
		"/[org]/resources/[resourceId]": [61,[2,12]],
		"/[org]/resources/[resourceId]/logs": [62,[2,12]],
		"/[org]/settings": [63,[2,13]],
		"/[org]/settings/business/billing": [64,[2,13]],
		"/[org]/settings/developer/api-keys": [65,[2,13]],
		"/[org]/teams": [66,[2]],
		"/[org]/teams/create": [68,[2]],
		"/[org]/teams/[teamId]": [67,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';